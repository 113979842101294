import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { RequestData, ServerResponse } from '@models/common/api-client';
import { clearUserData } from '@utils/common';
import { pickBy } from '@utils/lodash-utils';

const API_URL = process.env.NEXT_PUBLIC_API_URL;

/**
 * Handle Network Requests.
 * @param {string} endpoint - Api path.
 * @param {object} [config={}] - Config object.
 * @param {string} config.method - Method.
 * @param {object} config.data - Body for POST calls.
 * @param {string} config.token - Token for authenticated calls.
 * @param {object} config.headers - Additional headers
 */

const client = async <T, U>(
  endpoint: string,
  {
    data,
    token,
    headers,
    method,
    transform = true,
    useAPIUrl = true,
    ...rest
  }: RequestData<U> = {},
): Promise<ServerResponse<T>> => {
  const config: AxiosRequestConfig = {
    url: useAPIUrl ? `${API_URL}/${endpoint}` : `api/${endpoint}`,
    method: method || (data ? 'POST' : 'GET'),
    data: data ? JSON.stringify(data) : undefined,
    headers: pickBy({
      Authorization: token || undefined,
      'Content-Type': data ? 'application/json' : undefined,
      ...headers,
    }),
    transformResponse: [].concat(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      axios.defaults.transformResponse,
      (resp: ServerResponse<T>) => {
        if (transform && resp.items) {
          return resp.items;
        }
        return resp;
      },
    ),
    ...rest,
  };

  try {
    const response: AxiosResponse<ServerResponse<T>> = await axios(config);
    const { data: resData } = response;
    return resData;
  } catch (err) {
    if (token && err.response?.status === 401) {
      clearUserData();
      window.location.assign(window.location.href);
      err.response.data.message = 'Please re-authenticate.';
      return Promise.reject(err);
    }
    return Promise.reject(err);
  }
};

export { client };
