import React from 'react';

import usePersistentReducer from '@hooks/use-persistent-reducer';
import { YogaSaunaDispatch, YogaSaunaState } from '@models/yoga-sauna';
import yogaSaunaReducer, { yogaSaunaInitialState } from '@reducers/yoga-sauna';

const YogaSaunaContext = React.createContext<YogaSaunaState | undefined>(
  undefined,
);
const YogaSaunaDispatchContext = React.createContext<
  YogaSaunaDispatch | undefined
>(undefined);

const YogaSaunaProvider: React.FunctionComponent = ({ children }) => {
  const [state, dispatch] = usePersistentReducer(
    yogaSaunaReducer,
    yogaSaunaInitialState,
    'yoga_sauna',
  );
  return (
    <YogaSaunaContext.Provider value={state}>
      <YogaSaunaDispatchContext.Provider value={dispatch}>
        {children}
      </YogaSaunaDispatchContext.Provider>
    </YogaSaunaContext.Provider>
  );
};

const useYogaSaunaState = (): YogaSaunaState => {
  const context = React.useContext(YogaSaunaContext);
  if (context === undefined) {
    throw new Error(
      'useYogaSaunaState must be used within a YogaSaunaProvider',
    );
  }
  return context;
};

const useYogaSaunaDispatch = (): YogaSaunaDispatch => {
  const context = React.useContext(YogaSaunaDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useYogaSaunaDispatch must be used within a YogaSaunaProvider',
    );
  }
  return context;
};

const useYogaSauna = (): [YogaSaunaState, YogaSaunaDispatch] => [
  useYogaSaunaState(),
  useYogaSaunaDispatch(),
];

export {
  YogaSaunaProvider,
  useYogaSaunaState,
  useYogaSaunaDispatch,
  useYogaSauna,
};
