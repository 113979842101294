import React from 'react';
import { queryCache } from 'react-query';

import { useAsync } from '@hooks/use-async';
import {
  AuthContextValue,
  AuthProviderProps,
  LoginForm,
  RegisterFormParams,
} from '@models/auth';
import { ResetPasswordPayloadModel } from '@models/reset-password';
import { User } from '@models/user';
import { client } from '@services/api-client';
import * as auth from '@services/auth';
import { getUser } from '@services/user';

const fetchUser = async () => {
  let user = null;

  const token = auth.getToken();
  if (token) {
    user = await getUser({ token });
  }
  return user;
};

const AuthContext = React.createContext<AuthContextValue | undefined>(
  undefined,
);
AuthContext.displayName = 'AuthContext';

const AuthProvider: React.FunctionComponent<AuthProviderProps> = ({
  children,
  ...rest
}) => {
  const {
    data: user,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync<User>();

  React.useEffect(() => {
    run(fetchUser());
  }, [run]);

  const login = React.useCallback(
    async (form: LoginForm) =>
      auth.login(form).then((_user: User) => setData(_user)),
    [setData],
  );

  const register = React.useCallback(
    async (form: RegisterFormParams) =>
      auth.register(form).then((_user: User) => setData(_user)),
    [setData],
  );

  const logout = React.useCallback(async () => {
    await auth.logout();
    queryCache.clear();
    setData(null);
  }, [setData]);

  const forgotPassword = React.useCallback(async (email: string) => {
    await auth.forgotPassword(email);
  }, []);

  const resetPassword = React.useCallback(
    async (payload: ResetPasswordPayloadModel) => {
      await auth.resetPassword(payload);
    },
    [],
  );

  const refreshUser = React.useCallback(async () => {
    const updatedUser = await fetchUser();
    if (updatedUser) {
      setData(updatedUser);
    }
  }, [setData]);

  const value: AuthContextValue = React.useMemo(
    () => ({
      isError,
      isIdle,
      isLoading,
      isSuccess,
      login,
      logout,
      register,
      forgotPassword,
      resetPassword,
      refreshUser,
      status,
      user,
      error,
    }),
    [
      isError,
      isIdle,
      isLoading,
      isSuccess,
      login,
      logout,
      register,
      forgotPassword,
      resetPassword,
      refreshUser,
      status,
      user,
      error,
    ],
  );

  return (
    <AuthContext.Provider value={value} {...rest}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextValue => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

const useClient = (): typeof client => {
  const token = auth.getToken() as string;
  return React.useCallback(
    (endpoint, config) => client(endpoint, { ...config, token }),
    [token],
  );
};

export { AuthProvider, useAuth, useClient };
