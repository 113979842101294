import React from 'react';

import usePersistentReducer from '@hooks/use-persistent-reducer';
import { MembershipsDispatch, MembershipsState } from '@models/memberships';
import membershipsReducer, {
  membershipsInitialState,
} from '@reducers/memberships';

const MembershipsContext = React.createContext<MembershipsState | undefined>(
  undefined,
);
const MembershipsDispatchContext = React.createContext<
  MembershipsDispatch | undefined
>(undefined);

const MembershipsProvider: React.FunctionComponent = ({ children }) => {
  const [state, dispatch] = usePersistentReducer(
    membershipsReducer,
    membershipsInitialState,
    'memberships',
  );
  return (
    <MembershipsContext.Provider value={state}>
      <MembershipsDispatchContext.Provider value={dispatch}>
        {children}
      </MembershipsDispatchContext.Provider>
    </MembershipsContext.Provider>
  );
};

const useMembershipsState = (): MembershipsState => {
  const context = React.useContext(MembershipsContext);
  if (context === undefined) {
    throw new Error(
      'useMembershipsState must be used within a MembershipsProvider',
    );
  }
  return context;
};

const useMembershipsDispatch = (): MembershipsDispatch => {
  const context = React.useContext(MembershipsDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useMembershipsDispatch must be used within a MembershipsProvider',
    );
  }
  return context;
};

const useMemberships = (): [MembershipsState, MembershipsDispatch] => [
  useMembershipsState(),
  useMembershipsDispatch(),
];

export {
  MembershipsProvider,
  useMembershipsState,
  useMembershipsDispatch,
  useMemberships,
};
