import React, { Dispatch, Reducer, ReducerAction } from 'react';

import useIsMounted from '@hooks/use-is-mounted';
/**
 * Safe Dispatch - Prevents action dispatches when component is unmounted
 * @param dispatch - reducer dispatch function returned by useReducer
 */
const useSafeDispatch = <R extends Reducer<any, any>>(
  dispatch: Dispatch<ReducerAction<R>>,
): Dispatch<ReducerAction<R>> => {
  const isMounted = useIsMounted();

  return React.useCallback(
    (...args) => (isMounted.current ? dispatch(...args) : undefined),
    [dispatch, isMounted],
  );
};

export default useSafeDispatch;
