import { QueryConfig, useMutation, useQuery } from 'react-query';

import { ErrorType } from '@models/common/async-hook';
import { MutationResponse, QueryResponse } from '@models/react-query';
import { UpdateUserPayload, User, UserMembershipModel } from '@models/user';
import { client } from '@services/api-client';

import { getToken } from './auth';

const getUser = async ({ token }: { token: string }): Promise<User> => {
  const user = await client<User, undefined>('users/me', {
    token,
  });
  return user;
};

const fetchUserCards = (token: string): Promise<User[]> =>
  client<User, undefined>(
    'users/me?include=card,membership.*,membership.membership.*,membership.status.*',
    {
      token,
    },
  );

const useFetchUserCards = (
  config: QueryConfig<User, ErrorType | null> = {},
): QueryResponse<User> => {
  const token = getToken() as string;

  return useQuery({
    queryKey: 'userCard',
    queryFn: () => fetchUserCards(token),
    config: { ...config },
  });
};

const fetchUserMemberships = (token: string): Promise<UserMembershipModel[]> =>
  client<UserMembershipModel, undefined>(
    'memberships?fields=*,membership.*,status.*',
    {
      token,
    },
  );

const useFetchUserMemberships = (
  config: QueryConfig<UserMembershipModel[], ErrorType | null> = {},
): QueryResponse<UserMembershipModel[]> => {
  const token = getToken() as string;

  return useQuery({
    queryKey: 'userMemberships',
    queryFn: () => fetchUserMemberships(token),
    config,
  });
};

const updateUser = (id: string, payload: UpdateUserPayload): Promise<User> => {
  const token = getToken() as string;

  return client<User, UpdateUserPayload>(`users/${id}`, {
    data: { ...payload },
    method: 'PUT',
    token,
  });
};

const useUpdateUser = (
  id: string,
  config: QueryConfig<UpdateUserPayload, ErrorType | null> = {},
): MutationResponse<UpdateUserPayload> =>
  useMutation((payload: UpdateUserPayload) => updateUser(id, payload), {
    ...config,
  });

export { getUser, useFetchUserCards, useFetchUserMemberships, useUpdateUser };
