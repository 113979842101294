import { PricingModel } from './membership';

export interface MembershipsState {
  selectedMembership: PricingModel | null;
}

export enum MembershipActionTypes {
  SET_SELECTED_MEMBERSHIP = 'SET_SELECTED_MEMBERSHIP',
}

export type MembershipsActions = {
  type: MembershipActionTypes.SET_SELECTED_MEMBERSHIP;
  payload: PricingModel | null;
};

export type MembershipsReducerTypes = (
  state: MembershipsState,
  action: MembershipsActions,
) => MembershipsState;

export type MembershipsDispatch = React.Dispatch<MembershipsActions>;
