import { PricingModel } from '@models/memberships/membership';

import { GiftRecipientForm } from './gift-recipient';

export interface GiftState {
  selectedGiftType: PricingModel | null;
  isOneMonthGiftType: boolean;
  giftRecipientInfo: GiftRecipientForm | null;
}

export enum GiftActionTypes {
  SET_SELECTED_GIFT_TYPE = 'SET_SELECTED_GIFT_TYPE',
  SET_IS_ONE_MONTH_GIFT_TYPE = 'SET_IS_ONE_MONTH_GIFT_TYPE',
  SET_GIFT_RECIPIENT_INFO = 'SET_GIFT_RECIPIENT_INFO',
  RESET = 'RESET',
}

export type GiftActions =
  | {
      type: GiftActionTypes.SET_SELECTED_GIFT_TYPE;
      payload: PricingModel | null;
    }
  | { type: GiftActionTypes.SET_IS_ONE_MONTH_GIFT_TYPE; payload: boolean }
  | {
      type: GiftActionTypes.SET_GIFT_RECIPIENT_INFO;
      payload: GiftRecipientForm;
    }
  | {
      type: GiftActionTypes.RESET;
    };

export type GiftReducerType = (
  state: GiftState,
  action: GiftActions,
) => GiftState;

export type GiftDispatch = React.Dispatch<GiftActions>;
