import {
  MembershipActionTypes,
  MembershipsReducerTypes,
} from '@models/memberships';

export const membershipsInitialState = {
  selectedMembership: null,
};

const MembershipsReducer: MembershipsReducerTypes = (
  state = membershipsInitialState,
  action,
) => {
  switch (action.type) {
    case MembershipActionTypes.SET_SELECTED_MEMBERSHIP:
      return {
        ...state,
        selectedMembership: action.payload,
      };
    default:
      return state;
  }
};

export default MembershipsReducer;
