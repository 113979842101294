import { queryCache } from 'react-query';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import dayjs, { Dayjs, UnitType } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import Cookies from 'js-cookie';
import * as Yup from 'yup';

// import { FormatValueProps } from '@models/auth';
import { ErrorMessageError } from '@models/common/error';
import { InstructorModel } from '@models/instructors/instructors';
import { Location } from '@models/location';
import { SessionModel } from '@models/session';
import { UserMembershipModel } from '@models/user';
import {
  cookieTokenKey,
  DATE_FORMAT,
  MAX_NUMBER_OF_DAYS,
  MEMBERSHIP_ACTIVE_STATUS,
  MEMBERSHIP_CANCELLED_STATUS,
  MIN_AGE,
  radioButtonFields,
} from '@utils/constants';

import { isEmpty } from './lodash-utils';

dayjs.extend(advancedFormat);
dayjs.extend(LocalizedFormat);
const parseErrorMessage = (error: ErrorMessageError): string | null => {
  if (error?.response?.data?.message) {
    return error.response.data.message;
  }
  if (error?.message) {
    return error.message;
  }
  if (typeof error === 'string') {
    return error;
  }

  return null;
};

const clearUserData = (): void => {
  Cookies.remove(cookieTokenKey);
  localStorage.clear();
  queryCache.clear();
};

const isBrowser = (): boolean => typeof window !== 'undefined';

const parseUrlSlug = (slug: string): string =>
  slug ? slug.toLowerCase().replace(/ /g, '-') : '';

type CAF = (args?: CAFArgs) => unknown;
type CAFArgs = unknown;
const callAll = (...fns: CAF[]) => (...args: CAFArgs[]): unknown[] =>
  fns.map(fn => fn && fn(...args));

const callAllEach = (...fns: CAF[]) => (...args: CAFArgs[]): void => {
  fns.forEach(fn => fn && fn(...args));
};

const dateDifference = (
  dateA: string | Date | Dayjs,
  dateB: string | Date | Dayjs,
  granularity: UnitType = 'year',
): number => dayjs(dateA).diff(dateB, granularity);

const getAge = (date: string | Date | Dayjs): number =>
  dateDifference(dayjs(), date);

const isUnderAge = (date: string | Date | Dayjs): boolean =>
  getAge(date) < MIN_AGE;

const castArray = <T>(val: T | T[]): T[] => (Array.isArray(val) ? val : [val]);

const getDates = () => {
  const dates = [];
  for (let i = 0; i < MAX_NUMBER_OF_DAYS; i += 1) {
    const day = dayjs().add(i, 'day');
    dates.push({
      id: i,
      day: day.format('ddd'),
      date: day.format('D'),
      formattedDate: day.format('YYYY-MM-DD'),
    });
  }
  return dates;
};

const checkKeyEnterOrSpace = (
  e:
    | React.KeyboardEvent<HTMLDivElement>
    | React.KeyboardEvent<HTMLAnchorElement>
    | React.KeyboardEvent<HTMLButtonElement>,
): boolean =>
  e.key === 'Enter' ||
  e.key === ' ' ||
  e.key === 'Spacebar' ||
  e.which === 13 ||
  e.which === 32;

let stripePromise: Promise<Stripe | null>;
const getStripeInstance = (key: string | undefined): Promise<Stripe | null> => {
  if (!stripePromise && key) {
    stripePromise = loadStripe(key);
  }
  return stripePromise;
};

// const handleStripeErrors = (
//   error: StripeError,
//   existingCardErrors: CardErrorMessageModel,
// ) => {
//   let errorObj = {};
//   switch (error.code) {
//     case 'invalid_expiry_year_past':
//     case 'incomplete_expiry':
//     case 'invalid_expiry_year':
//     case 'invalid_expiry_month':
//       errorObj = { cardExpiry: error.message as string };
//       break;
//     case 'invalid_number':
//     case 'card_declined':
//     case 'expired_card':
//     case 'incomplete_number':
//     case 'incorrect_number':
//       errorObj = { cardNumber: error.message as string };
//       break;
//     case 'incorrect_cvc':
//     case 'incomplete_cvc':
//     case 'invalid_cvc':
//       errorObj = { cardCvc: error.message as string };
//       break;
//     default:
//       errorObj = { general: error.message as string };
//       break;
//   }
//   return { ...existingCardErrors, ...errorObj };
// };

const formatDateAndTime = (
  dateStr: string,
  timeStr: string,
  format = 'h:mma',
): string => {
  if (dateStr) {
    const splitTime = dateStr.split('T', 2);
    const date = splitTime[0];
    if (timeStr) {
      const time = timeStr.substr(0, 5);
      return dayjs(`${date} ${time}`).format(format);
    }
    return dayjs(date).format(format);
  }
  return '';
};

const hasWaitList = (slot: SessionModel): string | null => {
  const {
    available_spots: availableSpots,
    has_waitlist: hasWaitListStatus,
    waitlist_capacity: waitListCapacity,
    waitlist_reservation_user_count: waitListReservationUserCount,
    standard_reservation_user_count: standardReservationUserCount,
    capacity,
  } = slot;

  if (availableSpots.length > 0) {
    return null;
  }

  if (hasWaitListStatus && waitListCapacity === null) {
    return 'has_capacity';
  }

  if (
    hasWaitListStatus &&
    waitListCapacity !== null &&
    waitListReservationUserCount < waitListCapacity
  ) {
    return 'has_capacity';
  }

  if (
    hasWaitListStatus &&
    waitListCapacity !== null &&
    waitListReservationUserCount === waitListCapacity
  ) {
    return 'full';
  }

  if (!hasWaitListStatus && standardReservationUserCount === capacity) {
    return 'booking_full';
  }

  return null;
};

const filterByName = <T extends { firstName: string; lastName: string }>(
  list: T[] = [],
  query: string,
): T[] => {
  if (isEmpty(query) || !list) {
    return [...(list || [])];
  }

  const result = list.filter(item => {
    const name = item
      ? `${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()}`
      : '';
    return name.includes(query.toLowerCase().trim());
  });

  return result;
};

const getPriceInDollars = (priceInCents: number): number => priceInCents * 0.01;

const getSelectedDate = (date: string | undefined): string => {
  let correctDate = dayjs().format(DATE_FORMAT);
  if (date) {
    correctDate = dayjs(date).isBefore(dayjs())
      ? dayjs().format(DATE_FORMAT)
      : dayjs(date).format(DATE_FORMAT);
  }
  return correctDate;
};

const getRequiredFont = (fFamily: string): string => {
  if (fFamily === 'aryaSingle') {
    return 'arya-single, sans-serif';
  }
  if (fFamily === 'goldenPlain') {
    return 'golden-plain, sans-serif';
  }
  if (fFamily === 'helveticaNeue') {
    return 'Helvetica Neue';
  }
  return 'proxima-nova, sans-serif';
};

const getRatingDefaultValues = (rating: number): string =>
  rating <= 3 ? radioButtonFields.no : radioButtonFields.yes;

const getRatingsBooleanValue = (fieldValue: string): boolean =>
  fieldValue === radioButtonFields.yes;

const getDropdownOptionsFromEnum = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  enumObj: any,
): any =>
  Object.values(enumObj).map((val: typeof enumObj) => ({
    label: val,
    value: val,
  })) as any; // TODO: type this

const getDropdownValueValidationSchema = <T>(
  options: T,
): Yup.ObjectSchema<{ label: T; value: T }> =>
  Yup.object({
    label: Yup.mixed().oneOf(Object.values(options)).defined(),
    value: Yup.mixed().oneOf(Object.values(options)).defined(),
  }).nullable() as Yup.ObjectSchema<{ label: T; value: any }>;

const sortByName = (list: InstructorModel[]): InstructorModel[] => {
  const sortedList = list.sort((a, b) =>
    a.firstName.localeCompare(b.firstName),
  );
  return sortedList;
};

const getRoundedNumber = (value: number): string =>
  Number.isInteger(value) ? value.toString() : value.toFixed(2);

const parseJwt = (
  token: string,
): { email: string; iat: number; tokenId: string } | null => {
  if (!token) {
    return null;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(atob(base64));
};

const getScheduledToCancelMemberships = (
  memberships: UserMembershipModel[],
): UserMembershipModel[] => {
  const activeMembership = memberships.filter(
    item =>
      !item.cancelledDate &&
      !item.scheduleCancelDate &&
      item.status.name === MEMBERSHIP_ACTIVE_STATUS,
  );
  return activeMembership;
};

const getActiveMembership = (
  memberships: UserMembershipModel[],
): UserMembershipModel[] => {
  const activeMembership = memberships.filter(
    item => item.status.name !== MEMBERSHIP_CANCELLED_STATUS,
  );
  return activeMembership;
};

const getSeoFormattedTime = (date: string, time: string): string =>
  dayjs(`${date} ${time}`).format('MM-DD-YYYY HH:mm');

const parseInstructorNameSlug = (name = ''): string =>
  name ? name?.trim().toLowerCase() : '';

const getFormattedLocationAddress = (
  location: Location | undefined,
): string => {
  if (location) {
    return `${location.address1} ${
      location.address2 ? ` ${location.address2}` : ''
    }`;
  }
  return '';
};

const getFormattedCityStateZip = (location: Location | undefined): string => {
  if (location) {
    return `${location.city}, ${location.stateShortName} ${location.zip}`;
  }
  return '';
};

export {
  parseErrorMessage,
  clearUserData,
  isBrowser,
  parseUrlSlug,
  callAll,
  callAllEach,
  dateDifference,
  isUnderAge,
  formatDateAndTime,
  castArray,
  checkKeyEnterOrSpace,
  getDates,
  getStripeInstance,
  hasWaitList,
  filterByName,
  getPriceInDollars,
  getSelectedDate,
  getRequiredFont,
  getRatingDefaultValues,
  getRatingsBooleanValue,
  getDropdownOptionsFromEnum,
  getDropdownValueValidationSchema,
  sortByName,
  getRoundedNumber,
  parseJwt,
  getScheduledToCancelMemberships,
  getActiveMembership,
  getSeoFormattedTime,
  parseInstructorNameSlug,
  getFormattedLocationAddress,
  getFormattedCityStateZip,
};
