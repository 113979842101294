import React from 'react';

import usePersistentReducer from '@hooks/use-persistent-reducer';
import { YogaDispatch, YogaState } from '@models/yoga';
import yogaReducer, { yogaInitialState } from '@reducers/yoga';

const YogaContext = React.createContext<YogaState | undefined>(undefined);
const YogaDispatchContext = React.createContext<YogaDispatch | undefined>(
  undefined,
);

const YogaProvider: React.FunctionComponent = ({ children }) => {
  const [state, dispatch] = usePersistentReducer(
    yogaReducer,
    yogaInitialState,
    'yoga',
  );
  return (
    <YogaContext.Provider value={state}>
      <YogaDispatchContext.Provider value={dispatch}>
        {children}
      </YogaDispatchContext.Provider>
    </YogaContext.Provider>
  );
};

const useYogaState = (): YogaState => {
  const context = React.useContext(YogaContext);
  if (context === undefined) {
    throw new Error('useYogaState must be used within a YogaProvider');
  }
  return context;
};

const useYogaDispatch = (): YogaDispatch => {
  const context = React.useContext(YogaDispatchContext);
  if (context === undefined) {
    throw new Error('useYogaDispatch must be used within a YogaProvider');
  }
  return context;
};

const useYoga = (): [YogaState, YogaDispatch] => [
  useYogaState(),
  useYogaDispatch(),
];

export { YogaProvider, useYogaState, useYogaDispatch, useYoga };
