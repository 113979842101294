import { YogaActionTypes, YogaReducerType } from '@models/yoga';

export const yogaInitialState = {
  yogaFilters: null,
};

const yogaReducer: YogaReducerType = (state = yogaInitialState, action) => {
  switch (action.type) {
    case YogaActionTypes.SET_YOGA_FILTERS:
      return {
        ...state,
        yogaFilters: { ...action.payload },
      };
    case YogaActionTypes.CLEAR_YOGA_FILTERS:
      return {
        ...state,
        yogaFilters: yogaInitialState.yogaFilters,
      };
    default:
      return state;
  }
};

export default yogaReducer;
