import { SaunaActionTypes, SaunaReducerType } from '@models/sauna';

export const saunaInitialState = {
  saunaFilters: null,
};

const saunaReducer: SaunaReducerType = (state = saunaInitialState, action) => {
  switch (action.type) {
    case SaunaActionTypes.SET_SAUNA_FILTERS:
      return {
        ...state,
        saunaFilters: { ...action.payload },
      };
    case SaunaActionTypes.CLEAR_SAUNA_FILTERS:
      return {
        ...state,
        saunaFilters: saunaInitialState.saunaFilters,
      };
    default:
      return state;
  }
};

export default saunaReducer;
