const cookieTokenKey = '__auth_token__';
const cookieUserIdKey = '__userId__';
const squeezeUrl = 'https://www.squeezemassage.com';
const okHumansUrl = 'https://www.okayhumans.com';
const TFGcoUrl = 'https://www.thefeelgoodcompany.com';
const dryBarUrl = 'https://www.drybar.com ';
const girlIncUrl = 'https://girlsinc.org/';
const healtyKidsUrl = 'https://www.actionforhealthykids.org/';
const instagramUrl = 'https://www.instagram.com/thebrightside/';
const facebookUrl = 'https://www.facebook.com/thebrightsideyoga';
const freshTeamUrl = 'https://brightsideyoga.freshteam.com/jobs';

const MIN_AGE = 18;
const DATE_FORMAT = 'YYYY-MM-DD';
const MAX_NUMBER_OF_DAYS = 14;
const MIN_ALLOWED_DATE = 1930;

const SERVICE = {
  yoga: 'yoga',
  sauna: 'sauna',
};

const CONTACT_PHONE = '(888) 851-5119';
const CONTACT_EMAIL = 'help@brightsideyoga.com';

const DEEP_LINK = 'https://brightsider.test-app.link/home-page';

const CANCELLATION_BEFORE_12_HOUR =
  'Don’t worry, there won’t be a cancellation fee!';
const CANCELLATION_WITHIN_12_HOUR_YOGA =
  'Because this class is in the next 12 hours,<br/> you will not receive a refund.';
const CANCELLATION_WITHIN_12_HOUR_SAUNA =
  'Because this session is in the next 12 hours,<br/> you will not receive a refund.';
const CANCELLATION_UNLIMITED_YOGA =
  'Because this class is in the next 12 hours,<br/> your card will be charged';
const CANCELLATION_UNLIMITED_SAUNA =
  'Because this session is in the next 12 hours,<br/> your card will be charged';

const CANCELLED_STATUS = ['lateCancel', 'earlyCancel'];
const UPCOMING_STATUS = ['booked', 'waitlist', 'checkedIn'];
const COMPLETED_STATUS = ['noShow', 'completed'];

const MEMBERSHIP_CANCELLED_STATUS = 'cancelled';
const MEMBERSHIP_SUSPENDED_STATUS = 'suspended';
const MEMBERSHIP_ACTIVE_STATUS = 'active';

const MEMBER_CARD_PURCHASE_ERROR =
  'Cannot purchase a membership without a stored bankcard on file.';

const SUCCESSFULLY_PURCHASED_MEMBERSHIP =
  'Your membership was successfully purchased';

const CLASS_WAITLIST_FULL_ERROR = 'This class’s waitlist is full”';

const CARD_DECLINED_ERROR = 'Card was declined.';
const CARD_ERROR_MESSAGE = 'Unfortunately your credit card was declined.';
const CARD_UPDATE_MESSAGE = 'Please update it to proceed.';

const radioButtonFields = {
  yes: 'yes',
  no: 'no',
};

const orderRequiredNameArray = [
  'Nuun Sport: Tri-Berry (10pk)',
  'Nuun Recover: Lemonade (1pk)',
  'LMNT Electrolyte Drink Mix',
  'Feel Protein Bar',
  'JUST Water',
];

export {
  cookieTokenKey,
  cookieUserIdKey,
  squeezeUrl,
  okHumansUrl,
  TFGcoUrl,
  dryBarUrl,
  girlIncUrl,
  orderRequiredNameArray,
  freshTeamUrl,
  healtyKidsUrl,
  instagramUrl,
  facebookUrl,
  MIN_AGE,
  DATE_FORMAT,
  DEEP_LINK,
  MAX_NUMBER_OF_DAYS,
  MIN_ALLOWED_DATE,
  CANCELLED_STATUS,
  COMPLETED_STATUS,
  UPCOMING_STATUS,
  CONTACT_EMAIL,
  CONTACT_PHONE,
  CANCELLATION_BEFORE_12_HOUR,
  CANCELLATION_WITHIN_12_HOUR_YOGA,
  CANCELLATION_WITHIN_12_HOUR_SAUNA,
  CANCELLATION_UNLIMITED_YOGA,
  CANCELLATION_UNLIMITED_SAUNA,
  MEMBERSHIP_CANCELLED_STATUS,
  MEMBERSHIP_ACTIVE_STATUS,
  MEMBER_CARD_PURCHASE_ERROR,
  CARD_DECLINED_ERROR,
  CARD_ERROR_MESSAGE,
  CARD_UPDATE_MESSAGE,
  CLASS_WAITLIST_FULL_ERROR,
  radioButtonFields,
  SUCCESSFULLY_PURCHASED_MEMBERSHIP,
  MEMBERSHIP_SUSPENDED_STATUS,
  SERVICE,
};
