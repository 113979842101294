import React from 'react';

import usePersistentReducer from '@hooks/use-persistent-reducer';
import { SaunaDispatch, SaunaState } from '@models/sauna';
import saunaReducer, { saunaInitialState } from '@reducers/sauna';

const SaunaContext = React.createContext<SaunaState | undefined>(undefined);
const SaunaDispatchContext = React.createContext<SaunaDispatch | undefined>(
  undefined,
);

const SaunaProvider: React.FunctionComponent = ({ children }) => {
  const [state, dispatch] = usePersistentReducer(
    saunaReducer,
    saunaInitialState,
    'sauna',
  );
  return (
    <SaunaContext.Provider value={state}>
      <SaunaDispatchContext.Provider value={dispatch}>
        {children}
      </SaunaDispatchContext.Provider>
    </SaunaContext.Provider>
  );
};

const useSaunaState = (): SaunaState => {
  const context = React.useContext(SaunaContext);
  if (context === undefined) {
    throw new Error('useSaunaState must be used within a SaunaProvider');
  }
  return context;
};

const useSaunaDispatch = (): SaunaDispatch => {
  const context = React.useContext(SaunaDispatchContext);
  if (context === undefined) {
    throw new Error('useSaunaDispatch must be used within a SaunaProvider');
  }
  return context;
};

const useSauna = (): [SaunaState, SaunaDispatch] => [
  useSaunaState(),
  useSaunaDispatch(),
];

export { SaunaProvider, useSaunaState, useSaunaDispatch, useSauna };
