import { GiftActionTypes, GiftReducerType, GiftState } from '@models/gift';

export const giftInitialState: GiftState = {
  selectedGiftType: null,
  isOneMonthGiftType: false,
  giftRecipientInfo: null,
};

const giftReducer: GiftReducerType = (state, action) => {
  switch (action.type) {
    case GiftActionTypes.SET_SELECTED_GIFT_TYPE:
      return {
        ...state,
        selectedGiftType: action.payload,
      };

    case GiftActionTypes.SET_IS_ONE_MONTH_GIFT_TYPE:
      return {
        ...state,
        isOneMonthGiftType: action.payload,
      };
    case GiftActionTypes.SET_GIFT_RECIPIENT_INFO:
      return {
        ...state,
        giftRecipientInfo: action.payload,
      };
    case GiftActionTypes.RESET:
      return giftInitialState;

    default:
      return state;
  }
};

export default giftReducer;
