import { YogaSaunaActionTypes, YogaSaunaReducerType } from '@models/yoga-sauna';

export const yogaSaunaInitialState = {
  selectedLocation: null,
  selectedSession: null,
  guestEmail: null,
  isWaitListBooking: false,
  selectedPaymentMethod: null,
  isGuestBooking: false,
  selectedAppointmentDate: null,
  whenDataForGuest: null,
  guestEmailError: false,
};

const yogaReducer: YogaSaunaReducerType = (
  state = yogaSaunaInitialState,
  action,
) => {
  switch (action.type) {
    case YogaSaunaActionTypes.SET_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: action.payload,
      };
    case YogaSaunaActionTypes.RESET_SELECTED_DETAILS:
      return {
        ...state,
        guestEmail: yogaSaunaInitialState.guestEmail,
        selectedSession: yogaSaunaInitialState.selectedSession,
        isWaitListBooking: yogaSaunaInitialState.isWaitListBooking,
        selectedPaymentMethod: yogaSaunaInitialState.selectedPaymentMethod,
        isGuestBooking: yogaSaunaInitialState.isGuestBooking,
        selectedAppointmentDate: yogaSaunaInitialState.selectedAppointmentDate,
        whenDataForGuest: yogaSaunaInitialState.whenDataForGuest,
      };
    case YogaSaunaActionTypes.SET_SELECTED_SESSION:
      return {
        ...state,
        selectedSession: action.payload,
      };
    case YogaSaunaActionTypes.SET_GUEST_EMAIL:
      return {
        ...state,
        guestEmail: action.payload,
      };
    case YogaSaunaActionTypes.SET_IS_GUEST_BOOKING:
      return {
        ...state,
        isGuestBooking: action.payload,
      };
    case YogaSaunaActionTypes.SET_IS_WAITLIST_BOOKING:
      return {
        ...state,
        isWaitListBooking: action.payload,
      };
    case YogaSaunaActionTypes.SET_SELECTED_PAYMENT_METHOD:
      return {
        ...state,
        selectedPaymentMethod: action.payload,
      };
    case YogaSaunaActionTypes.SET_SELECTED_DATE:
      return {
        ...state,
        selectedAppointmentDate: action.payload,
      };
    case YogaSaunaActionTypes.SET_WHEN_DATA_FOR_GUEST:
      return {
        ...state,
        whenDataForGuest: action.payload,
      };
    case YogaSaunaActionTypes.SET_GUEST_EMAIL_ERROR:
      return {
        ...state,
        guestEmailError: action.payload,
      };
    default:
      return state;
  }
};

export default yogaReducer;
