import { GuestWhenData, LocationService } from '@models/location';
import { PricingModel } from '@models/memberships/membership';
import { SessionModel } from '@models/session';

export interface YogaSaunaState {
  selectedLocation: LocationService | null;
  selectedSession: SessionModel | null;
  isGuestBooking: boolean;
  guestEmail: string | null;
  isWaitListBooking: boolean;
  selectedPaymentMethod: PricingModel | null;
  selectedAppointmentDate: string | null;
  whenDataForGuest: GuestWhenData | null;
  guestEmailError: boolean;
}

export enum YogaSaunaActionTypes {
  SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION',
  RESET_SELECTED_DETAILS = 'RESET_SELECTED_DETAILS',
  SET_SELECTED_SESSION = 'SET_SELECTED_SESSION',
  SET_GUEST_EMAIL = 'SET_GUEST_EMAIL',
  SET_GUEST_EMAIL_ERROR = 'SET_GUEST_EMAIL_ERROR',
  SET_IS_GUEST_BOOKING = 'SET_IS_GUEST_BOOKING',
  SET_IS_WAITLIST_BOOKING = 'SET_IS_WAITLIST_BOOKING',
  SET_SELECTED_PAYMENT_METHOD = 'SET_SELECTED_PAYMENT_METHOD',
  SET_SELECTED_DATE = 'SET_SELECTED_DATE',
  SET_WHEN_DATA_FOR_GUEST = 'SET_WHEN_DATA_FOR_GUEST',
}

export type YogaSaunaActions =
  | {
      type: YogaSaunaActionTypes.SET_SELECTED_LOCATION;
      payload: LocationService | null;
    }
  | { type: YogaSaunaActionTypes.RESET_SELECTED_DETAILS }
  | {
      type: YogaSaunaActionTypes.SET_WHEN_DATA_FOR_GUEST;
      payload: GuestWhenData | null;
    }
  | {
      type: YogaSaunaActionTypes.SET_SELECTED_SESSION;
      payload: SessionModel | null;
    }
  | { type: YogaSaunaActionTypes.SET_GUEST_EMAIL; payload: string | null }
  | { type: YogaSaunaActionTypes.SET_IS_WAITLIST_BOOKING; payload: boolean }
  | {
      type: YogaSaunaActionTypes.SET_SELECTED_PAYMENT_METHOD;
      payload: PricingModel | null;
    }
  | {
      type: YogaSaunaActionTypes.SET_IS_GUEST_BOOKING;
      payload: boolean;
    }
  | {
      type: YogaSaunaActionTypes.SET_GUEST_EMAIL_ERROR;
      payload: boolean;
    }
  | {
      type: YogaSaunaActionTypes.SET_SELECTED_DATE;
      payload: string | null;
    };

export type YogaSaunaReducerType = (
  state: YogaSaunaState,
  action: YogaSaunaActions,
) => YogaSaunaState;

export type YogaSaunaDispatch = React.Dispatch<YogaSaunaActions>;
