import Cookies from 'js-cookie';

import { AuthReturn, LoginForm, RegisterFormParams } from '@models/auth';
import { SuccessResponse } from '@models/common/api-client';
import {
  ResetPasswordPayloadModel,
  ValidateUserEmailpayload,
} from '@models/reset-password';
import { User, ValidateUserEmailResponse } from '@models/user';
import { client } from '@services/api-client';
import { clearUserData } from '@utils/common';
import { cookieTokenKey } from '@utils/constants';

const getToken = (): string | undefined => Cookies.get(cookieTokenKey);

const handleAuthResponse = (user: AuthReturn): User => {
  Cookies.set(cookieTokenKey, user.sessionToken, { expires: 90 });

  // This is just to make sure the types stay consistent as the auth calls would have the token, and the user calls don't
  const { sessionToken, ...rest } = user;
  return rest;
};

const login = async (data: LoginForm): Promise<User> => {
  const result = await client<AuthReturn, LoginForm>('users/login', { data });
  return handleAuthResponse(result);
};

const register = async (data: RegisterFormParams): Promise<User> => {
  let payload = { ...data };
  // If user is referred by another user, pass referrer email id in register API call
  if (window?.referrerEmailId) {
    payload = {
      ...payload,
      referrerEmailId: window.referrerEmailId,
    };
  }
  const result = await client<AuthReturn, RegisterFormParams>('users', {
    data: payload,
  });
  return handleAuthResponse(result);
};

const logout = async (): Promise<void> => {
  await client<SuccessResponse, undefined>(`users/logout`, {
    method: 'DELETE',
    token: getToken(),
  });

  clearUserData();
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const forgotPassword = (email: string) =>
  client<SuccessResponse, undefined>(
    `users/forgot_password?email=${encodeURIComponent(email)}`,
  );

const validateUserEmail = (
  payload: ValidateUserEmailpayload,
): Promise<ValidateUserEmailResponse> => {
  const token = getToken() as string;

  return client<ValidateUserEmailResponse, ValidateUserEmailpayload>(
    'users/validate-email',
    {
      data: { ...payload },
      method: 'POST',
      token,
    },
  );
};

const resetPassword = (payload: ResetPasswordPayloadModel): Promise<User> => {
  const token = getToken() as string;

  return client<User, ResetPasswordPayloadModel>('users/reset_password', {
    data: { ...payload },
    method: 'POST',
    token,
  });
};

export {
  getToken,
  login,
  register,
  logout,
  clearUserData,
  forgotPassword,
  resetPassword,
  validateUserEmail,
};
