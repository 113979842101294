import { FilterTypes } from '@models/session';

export interface YogaState {
  yogaFilters: FilterTypes | null;
}

export enum YogaActionTypes {
  SET_YOGA_FILTERS = 'SET_YOGA_FILTERS',
  CLEAR_YOGA_FILTERS = 'CLEAR_YOGA_FILTERS',
}

export type YogaActions =
  | {
      type: YogaActionTypes.SET_YOGA_FILTERS;
      payload: FilterTypes;
    }
  | {
      type: YogaActionTypes.CLEAR_YOGA_FILTERS;
    };

export type YogaReducerType = (
  state: YogaState,
  action: YogaActions,
) => YogaState;

export type YogaDispatch = React.Dispatch<YogaActions>;
