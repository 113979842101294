import { FilterTypes } from '@models/session';

export interface SaunaState {
  saunaFilters: FilterTypes | null;
}

export enum SaunaActionTypes {
  SET_SAUNA_FILTERS = 'SET_SAUNA_FILTERS',
  CLEAR_SAUNA_FILTERS = 'CLEAR_SAUNA_FILTERS',
}

export type SaunaActions =
  | {
      type: SaunaActionTypes.SET_SAUNA_FILTERS;
      payload: FilterTypes;
    }
  | {
      type: SaunaActionTypes.CLEAR_SAUNA_FILTERS;
    };

export type SaunaReducerType = (
  state: SaunaState,
  action: SaunaActions,
) => SaunaState;

export type SaunaDispatch = React.Dispatch<SaunaActions>;
