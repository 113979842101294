import React from 'react';
import { queryCache, QueryConfig, useMutation, useQuery } from 'react-query';
import qs from 'qs';

import { useClient } from '@context/auth';
import {
  AddonsModel,
  AddonsSalesTaxPayload,
  AddonsSalesTaxResponse,
  BuyAddonsParamModel,
} from '@models/addons';
import { IFieldsAndFilters } from '@models/common/api-client';
import { ErrorType } from '@models/common/async-hook';
import { StaticInfo } from '@models/common/config';
import { InstructorModel } from '@models/instructors/instructors';
import { Location } from '@models/location';
import { MutationResponse, QueryResponse } from '@models/react-query';
import {
  AvailabilityParamModel,
  ClassTypesModel,
  DependentKeyModel,
  FilterTimesModel,
  NextAvailableDatePayload,
  SessionModel,
} from '@models/session';
import {
  CreateAppointmentParamModel,
  CreateAppointmentResponse,
} from '@models/yoga-sauna/appointment';
import { PostBookingTodos } from '@models/yoga-sauna/post-booking-flow';
import {
  CreateReviewAndRatingPayload,
  CreateReviewAndRatingResponse,
} from '@models/yoga-sauna/ratings';
import { SignWaiver, SignWaiverPayload } from '@models/yoga-sauna/sign-waiver';
import { client } from '@services/api-client';
import { constructUrl, parseFilters } from '@utils/api';
import { pickBy } from '@utils/lodash-utils';

interface AvailabilityPrams extends AvailabilityParamModel {
  locationId: number | undefined;
}

const fetchLocations = (
  config: IFieldsAndFilters<Partial<Location>> = {},
): Promise<Location[]> => {
  const requestUrl = constructUrl<Partial<Location>>('locations', {
    ...config,
    fields: ['*.service.service.*'],
  });
  return client<Location[], undefined>(requestUrl);
};

const useLocations = (
  config: QueryConfig<Location[], ErrorType | null> = {},
): QueryResponse<Location[]> =>
  useQuery({
    queryKey: 'locations',
    queryFn: () => fetchLocations(),
    ...config,
    staleTime: 1000 * 60 * 60,
  });

const useLocationDetail = (
  locationId: number,
  config: QueryConfig<Location, ErrorType | null> = {},
): QueryResponse<Location> =>
  useQuery({
    queryKey: ['locations', locationId],
    queryFn: () =>
      fetchLocations({
        filters: { id: locationId },
      }).then(t => t[0]),
    config: {
      ...config,
      staleTime: 1000 * 60 * 60,
    },
  });

const fetchAvailability = (
  params: AvailabilityPrams,
): Promise<SessionModel[]> => {
  const {
    startDate,
    endDate,
    startTime,
    endTime,
    serviceTypeIds,
    instructorIds,
    locationId,
    serviceId,
    filterTimes,
  } = params;

  const formattedFilterTimes = qs.stringify(filterTimes);
  const filters = {
    startDate,
    endDate,
    startTime,
    endTime,
    serviceTypeIds,
    instructorIds,
    serviceId,
  };
  const allFilters = pickBy(filters);
  let parseAndConstructFilterParams = parseFilters(allFilters, '&');
  const queryFormattedURI = encodeURIComponent(formattedFilterTimes).toString();

  if (filterTimes && queryFormattedURI.length > 0 && queryFormattedURI) {
    parseAndConstructFilterParams += `&filterTimes=${queryFormattedURI}`;
  }

  return client<SessionModel, undefined>(
    `locations/${locationId}/availability?${parseAndConstructFilterParams}`,
  );
};

const useAvailability = (
  params: AvailabilityPrams,
  config: QueryConfig<SessionModel[], ErrorType | null> = {},
  dependentKey: DependentKeyModel,
  queryKey: string,
): QueryResponse<SessionModel[]> =>
  useQuery({
    queryKey: [queryKey, dependentKey],
    queryFn: () => fetchAvailability(params),
    config,
  });

const fetchNextAvailableDate = (
  params: NextAvailableDatePayload,
): Promise<string> => {
  const {
    startFrom,
    serviceTypeIds,
    instructorIds,
    locationId,
    serviceId,
    filterTimes,
  } = params;

  const filterTimesArray: FilterTimesModel[] = [];
  if (filterTimes) {
    filterTimes.forEach((element: string) => {
      const splitTime = element.split('-');

      filterTimesArray.push({
        startTime: splitTime[0],
        endTime: splitTime[1],
      });
    });
  }

  const formattedFilterTimes = qs.stringify(filterTimesArray);
  const filters = {
    startFrom,
    serviceTypeIds,
    instructorIds,
    serviceId,
  };
  const allFilters = pickBy(filters);
  let parseAndConstructFilterParams = parseFilters(allFilters, '&');
  const queryFormattedURI = encodeURIComponent(formattedFilterTimes).toString();

  if (filterTimes && queryFormattedURI.length > 0 && queryFormattedURI) {
    parseAndConstructFilterParams += `&filterTimes=${queryFormattedURI}`;
  }

  return client<string, undefined>(
    `locations/${locationId}/nextAvailableDate?${parseAndConstructFilterParams}`,
  );
};

const useFetchNextAvailableDate = (
  params: NextAvailableDatePayload,
  config: QueryConfig<string, ErrorType | null> = {},
  dependentKey: string,
  queryKey: string,
): QueryResponse<string> =>
  useQuery({
    queryKey: [queryKey, dependentKey],
    queryFn: () => fetchNextAvailableDate(params),
    config,
  });

const fetchInstructors = (
  config: IFieldsAndFilters<Partial<InstructorModel>> = {},
): Promise<InstructorModel[]> => {
  const requestUrl = constructUrl<Partial<InstructorModel>>(
    'instructors',
    config,
  );
  return client<InstructorModel, undefined>(requestUrl);
};

const useInstructors = (
  { filters }: IFieldsAndFilters<Partial<InstructorModel>> = {},
  config: QueryConfig<InstructorModel[], ErrorType | null> = {},
): QueryResponse<InstructorModel[]> =>
  useQuery({
    queryKey: 'instructors',
    queryFn: () => fetchInstructors({ filters }),
    config,
  });

const fetchClassTypes = (): Promise<ClassTypesModel[]> => {
  const fields = '*,types.*';
  return client<ClassTypesModel, undefined>(`services?fields=${fields}`);
};

const useClassTypes = (
  config: QueryConfig<ClassTypesModel[], ErrorType | null> = {},
): QueryResponse<ClassTypesModel[]> =>
  useQuery({
    queryKey: 'services',
    queryFn: fetchClassTypes,
    config: { ...config },
  });

const fetchStaticInfo = (): Promise<StaticInfo> =>
  client<StaticInfo, undefined>(`appinfo/shared`);

const useStaticInfo = (
  config: QueryConfig<StaticInfo, ErrorType | null> = {},
): QueryResponse<StaticInfo> =>
  useQuery({
    queryKey: 'configs',
    queryFn: fetchStaticInfo,
    config: { ...config },
  });

const fetchAddons = (
  params: IFieldsAndFilters<Partial<AddonsModel> & { 'service.ne'?: string }>,
): Promise<AddonsModel[]> => {
  const { filters } = params;
  const allFilters = {
    sortField: 'priceInCents',
    sortOrder: 'asc',
    class: 'addons',
    ...filters,
  };

  return client<AddonsModel, undefined>(
    `products?filters=${parseFilters(allFilters, '%26')}`,
  );
};

const useFetchAddons = (
  config: QueryConfig<AddonsModel[], ErrorType | null> = {},
  typeFilter: { 'service.ne'?: string },
  queryKey: string,
): QueryResponse<AddonsModel[]> =>
  useQuery({
    queryKey,
    queryFn: () =>
      fetchAddons({
        filters: {
          ...typeFilter,
        },
      }),
    config: { ...config },
  });

const useCreateAppointment = (
  config: QueryConfig<CreateAppointmentResponse, ErrorType | null> = {},
): MutationResponse<CreateAppointmentResponse, CreateAppointmentParamModel> => {
  const authenticatedClient = useClient();

  return useMutation(
    (payload: CreateAppointmentParamModel) =>
      authenticatedClient<
        CreateAppointmentResponse,
        CreateAppointmentParamModel
      >(`appointments`, { data: payload }),
    { ...config },
  );
};

const useBuyAddons = (
  appointmentId: number,
  config: QueryConfig<BuyAddonsParamModel, ErrorType | null> = {},
): MutationResponse<BuyAddonsParamModel> => {
  const authenticatedClient = useClient();

  return useMutation(
    (payload: BuyAddonsParamModel) =>
      authenticatedClient(`appointments/${appointmentId}/addons`, {
        data: payload,
      }),
    { ...config },
  );
};

const fetchSignWaiver = (
  authenticatedClient: typeof client,
): Promise<SignWaiver> => {
  const requestUrl = 'users/waiver';
  return authenticatedClient<SignWaiver, undefined>(requestUrl);
};

const useSignWaiver = (
  config: QueryConfig<SignWaiver, ErrorType | null> = {},
): QueryResponse<SignWaiver> => {
  const authenticatedClient = useClient();

  return useQuery({
    queryKey: 'waiver',
    queryFn: () => fetchSignWaiver(authenticatedClient),
    config,
  });
};

const usePrefetchSignWaiver = (): (() => void) => {
  const authenticatedClient = useClient();

  return React.useCallback(async () => {
    queryCache.removeQueries('waiver');
    await queryCache.prefetchQuery('waiver', () =>
      fetchSignWaiver(authenticatedClient),
    );
  }, [authenticatedClient]);
};

const useCreateSignWaiver = (
  config: QueryConfig<SignWaiverPayload, ErrorType | null> = {},
): MutationResponse<SignWaiverPayload> => {
  const authenticatedClient = useClient();

  return useMutation(
    (payload: SignWaiverPayload) =>
      authenticatedClient('users/waiver', { data: payload }),
    { ...config },
  );
};

const fetchPostBookingTodos = (
  authenticatedClient: typeof client,
): Promise<PostBookingTodos> =>
  authenticatedClient<PostBookingTodos, undefined>('users/todo');

const usePostBookingTodos = (
  config: QueryConfig<PostBookingTodos, ErrorType | null> = {},
): QueryResponse<PostBookingTodos> => {
  const authenticatedClient = useClient();

  return useQuery({
    queryKey: 'postBookingTodos',
    queryFn: () => fetchPostBookingTodos(authenticatedClient),
    config,
  });
};

const usePrefetchPostBookingTodos = (): (() => void) => {
  const authenticatedClient = useClient();

  return React.useCallback(async () => {
    queryCache.removeQueries('postBookingTodos');
    await queryCache.prefetchQuery('postBookingTodos', () =>
      fetchPostBookingTodos(authenticatedClient),
    );
  }, [authenticatedClient]);
};

const useCreateReviewAndRating = (
  config: QueryConfig<CreateReviewAndRatingResponse, ErrorType | null> = {},
): MutationResponse<
  CreateReviewAndRatingResponse,
  CreateReviewAndRatingPayload
> => {
  const authenticatedClient = useClient();

  return useMutation(
    (payload: CreateReviewAndRatingPayload) =>
      authenticatedClient<
        CreateReviewAndRatingResponse,
        CreateReviewAndRatingPayload
      >('review', { data: payload }),
    { ...config },
  );
};

const useAddonsTaxData = (
  config: QueryConfig<AddonsSalesTaxResponse, ErrorType | null> = {},
): MutationResponse<AddonsSalesTaxResponse, AddonsSalesTaxPayload> => {
  const authenticatedClient = useClient();

  return useMutation(
    (payload: AddonsSalesTaxPayload) =>
      authenticatedClient<AddonsSalesTaxResponse, AddonsSalesTaxPayload>(
        'appointments/addons/tax',
        { data: payload },
      ),
    { ...config },
  );
};

export {
  fetchLocations,
  useLocations,
  useLocationDetail,
  fetchAvailability,
  useAvailability,
  useFetchNextAvailableDate,
  fetchInstructors,
  useInstructors,
  fetchClassTypes,
  useClassTypes,
  fetchStaticInfo,
  useStaticInfo,
  useCreateAppointment,
  fetchAddons,
  useFetchAddons,
  useBuyAddons,
  useSignWaiver,
  usePrefetchSignWaiver,
  useCreateSignWaiver,
  usePostBookingTodos,
  usePrefetchPostBookingTodos,
  useCreateReviewAndRating,
  useAddonsTaxData,
};
