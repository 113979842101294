const isEmpty = (obj: any): boolean =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

const pick = (
  object: Record<string, any>,
  keys: string[],
): Partial<typeof object> =>
  keys.reduce((obj, key) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {} as Partial<typeof object>);

const pickBy = <T extends Record<string, any>>(object: T): Partial<T> | T => {
  const obj = {} as T | Partial<T>;

  Object.keys(object).forEach((key: keyof T) => {
    if (object[key]) {
      obj[key] = object[key];
    }
  });

  return obj;
};

export { isEmpty, pickBy, pick };
