import { IFieldsAndFilters } from '@models/common/api-client';
import { isEmpty } from '@utils/lodash-utils';

const parseFilters = (obj: Record<string, any>, joinStr: string): string => {
  const result = Object.keys(obj).reduce((acc, prev) => {
    if (!isEmpty(obj[prev])) {
      acc.push(
        `${prev}=${Array.isArray(obj[prev]) ? obj[prev].join(',') : obj[prev]}`,
      );
    }
    return acc;
  }, [] as string[]);

  return result.join(joinStr);
};

const parseFields = (arr: string[]): string => arr.join(',');

const parseFieldsAndFilters = (
  fields: string[],
  filters: Record<string, any>,
  joinStr: string,
): { fields: string; filters: string } => ({
  fields: parseFields(fields),
  filters: parseFilters(filters, joinStr),
});

const constructUrl = <T>(
  base: string,
  config: IFieldsAndFilters<T>,
): string => {
  const { fields = [], filters = {} } = config;
  let parsedUrl = base;
  const parsedFields = parseFields(fields);
  const parsedFilters = parseFilters(filters, '%26');

  if (!isEmpty(parsedFields)) {
    parsedUrl = `${parsedUrl}?fields=${parsedFields}`;
  }

  if (!isEmpty(parsedFilters)) {
    parsedUrl = `${parsedUrl}${
      !isEmpty(fields) ? '&' : '?'
    }filters=${parsedFilters}`;
  }

  return parsedUrl;
};

export { parseFields, parseFilters, parseFieldsAndFilters, constructUrl };
